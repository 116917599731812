// React
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { getArticles } from "src/redux/thunks/article/articleThunk";
import { getArticlesLoadingStatus } from "src/redux/selectors/articleSelector";
import { getTags } from "src/redux/thunks/tags/getTags";
import { getTagsLoadingStatus } from "src/redux/selectors/tagSelector";
import Loader from "src/components/atoms/loader/Loader";

import PageTop from "src/components/molecules/page-top/PageTop";

// CSS
import "src/assets/scss/Global.scss";

const App = ({
  loadArticles,
  loadTags,
  articlesLoading,
  tagsLoading,
  children,
}) => {
  useEffect(() => {
    loadArticles();
    loadTags();
  }, []);

  return (
    <>
      <Helmet>
        <title>Recipe finder</title>
        <meta name="description" content="A collection of favourite recipes" />
        <meta name="theme-color" content="#3b85a4" />
      </Helmet>

      <PageTop />

      {articlesLoading || tagsLoading ? (
        <div className="container container--page">
          <Loader />
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

App.propTypes = {
  loadArticles: PropTypes.func.isRequired,
  loadTags: PropTypes.func.isRequired,
  articlesLoading: PropTypes.bool,
  tagsLoading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  articlesLoading: getArticlesLoadingStatus(state),
  tagsLoading: getTagsLoadingStatus(state),
});

const mapDispatchToProps = {
  loadArticles: getArticles,
  loadTags: getTags,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
