import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import PageHeader from "src/components/molecules/page-header/PageHeader";
import {
  getAllShops,
  getShopsLoadingStatus,
} from "src/redux/selectors/shopSelector";
import { getShops } from "src/redux/thunks/shops/getShops";
import { isEmpty } from "lodash";

import ShopList from "src/components/organisms/shop-list/ShopList";
import Loader from "src/components/atoms/loader/Loader";

const ShopLocal = ({ allShops, loadShops, shopsLoading }) => {
  useEffect(() => {
    if (isEmpty(allShops)) {
      loadShops();
    }
  }, [allShops]);

  return (
    <>
      <Helmet>
        <title>Shop local | Recipe Finder</title>
        <meta
          name="description"
          content={`Learn about close-by grocers, butchers, and eco friendly businesses to inspire you to shop more local.`}
        />
      </Helmet>

      {shopsLoading ? (
        <div className="container container--page">
          <Loader />
        </div>
      ) : (
        <>
          <PageHeader
            title="Shop local"
            introText="With online shopping so easy and accessible, it’s easy to forget what’s on your doorstep, and you have no say in where the food you eat is sourced from. Learn about close-by grocers, butchers, and eco friendly businesses to inspire you to shop more local. "
          />

          <div className="container container--page">
            <ShopList allShops={allShops} shopsLoading={shopsLoading} />
          </div>
        </>
      )}
    </>
  );
};

ShopLocal.propTypes = {
  loadShops: PropTypes.func.isRequired,
  shopsLoading: PropTypes.bool,
  allShops: PropTypes.array,
};

const mapStateToProps = (state) => ({
  shopsLoading: getShopsLoadingStatus(state),
  allShops: getAllShops(state),
});

const mapDispatchToProps = {
  loadShops: getShops,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShopLocal)
);
