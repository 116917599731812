import React, { useState } from "react";
import DocumentToHtml from "src/helpers/renderers/documentToHtml";
import PropTypes from "prop-types";

import "src/components/molecules/month-list-item/MonthListItem.scss";

const MonthListItem = ({ month, ingredients }) => {
  const [expanderIsOpen, setExpanderOpen] = useState(false);

  return (
    <li className="month-list-item shadow">
      <h3 className="visually-hidden">{month}</h3>

      <button
        aria-controls={`${month}-ingredients-expander`}
        aria-expanded={expanderIsOpen}
        onClick={() => setExpanderOpen(!expanderIsOpen)}
        className="month-list-item__toggle"
      >
        <span className="visually-hidden">View </span>
        <span className="text-capitalise">{month}</span>
        <span className="visually-hidden"> ingredients</span>
      </button>

      <div
        id={`${month}-ingredients-expander`}
        className={
          expanderIsOpen
            ? `month-list-item__expander month-list-item__expander--open`
            : "month-list-item__expander"
        }
      >
        <div
          className={`month-list-item__expander-inner ${
            !ingredients ? "month-list-item__expander-inner--empty" : ""
          }`}
        >
          {ingredients ? (
            <DocumentToHtml document={ingredients} />
          ) : (
            <p>No ingredients</p>
          )}
        </div>
      </div>
    </li>
  );
};

MonthListItem.propTypes = {
  month: PropTypes.string.isRequired,
  ingredients: PropTypes.object,
};

export default MonthListItem;
