import React from 'react';
import PropTypes from 'prop-types';

import ArticleSection from 'src/components/organisms/article-section/ArticleSection';
import DocumentToHtml from 'src/helpers/renderers/documentToHtml';

import './ArticleSteps.scss';

function ArticleSteps(props) {
  const { steps } = props;

  return (
    <ArticleSection title="Steps">
      <div className="article-steps">
        <DocumentToHtml document={steps} />
      </div>
    </ArticleSection>
  );
}

ArticleSteps.propTypes = {
  steps: PropTypes.object.isRequired
};

export default ArticleSteps
