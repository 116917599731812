export function seasonalIngredientsFetchError(bool) {
  return {
    type: "SEASONAL_INGREDIENTS_FETCH_ERROR",
    hasErrored: bool,
  };
}
export function seasonalIngredientsLoading(bool) {
  return {
    type: "SEASONAL_INGREDIENTS_LOADING",
    isLoading: bool,
  };
}
export function seasonalIngredientsFetched(seasonalIngredients) {
  return {
    type: "SEASONAL_INGREDIENTS_FETCHED",
    seasonalIngredients,
  };
}
