export function tagsFetchError(bool) {
  return {
      type: 'TAGS_FETCH_ERROR',
      hasErrored: bool
  };
}
export function tagsLoading(bool) {
  return {
      type: 'TAGS_LOADING',
      isLoading: bool
  };
}
export function tagsFetched(tags) {
  return {
      type: 'TAGS_FETCHED',
      tags
  };
}
export function tagsFiltered(tags) {
  return {
      type: 'TAGS_FILTERED',
      tags
  };
}