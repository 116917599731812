import { combineReducers } from "redux";

import {
  articles,
  articlesFetchError,
  articlesLoading,
} from "src/redux/reducers/article/articleReducer";
import {
  tags,
  tagsFetchError,
  tagsLoading,
  tagsFiltered,
} from "src/redux/reducers/tags/tagsReducer";
import {
  shops,
  shopsFetchError,
  shopsLoading,
  shopsFiltered,
} from "src/redux/reducers/shops/shopsReducer";
import {
  seasonalIngredients,
  seasonalIngredientsFetchError,
  seasonalIngredientsLoading,
} from "src/redux/reducers/seasonal-ingredients/seasonalIngredientsReducer";

export default combineReducers({
  articles,
  articlesFetchError,
  articlesLoading,
  tags,
  tagsFetchError,
  tagsLoading,
  tagsFiltered,
  shops,
  shopsFetchError,
  shopsLoading,
  shopsFiltered,
  seasonalIngredients,
  seasonalIngredientsFetchError,
  seasonalIngredientsLoading,
});
