import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { isEqual } from "lodash";

import {
  getAllTags,
  getFilteredTagSlugs,
} from "src/redux/selectors/tagSelector";
import { getAllArticles } from "src/redux/selectors/articleSelector";

import ArticleFilters from "src/components/molecules/article-filters/ArticleFilters";
import ArticleList from "src/components/organisms/article-list/ArticleList";

class TagPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredArticles: null,
      activeTagData: null,
    };
  }

  componentDidMount() {
    this.buildDataForPage();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.filteredTags, this.props.filteredTags)) {
      this.buildDataForPage();
    }
  }

  filterArticlesByActiveTags() {
    const filteredArticles = this.props.articles.filter((article) => {
      const activeTags = this.props.filteredTags;

      if (activeTags && activeTags.length) {
        return activeTags.every((activeTag) =>
          article.tags.find((tag) => tag.slug === activeTag)
        );
      }

      return true;
    });

    this.setState({
      filteredArticles,
    });
  }

  buildDataForPage(prevTagId) {
    this.filterArticlesByActiveTags();
  }

  renderPageBody(filteredArticles) {
    if (filteredArticles && filteredArticles.length) {
      // has filtered items
      return <ArticleList articles={filteredArticles} />;
    }

    // else, empty filtered list
    return <div>No recipes found, try reducing your filters!</div>;
  }

  render() {
    const { filteredArticles } = this.state;

    return (
      <>
        <Helmet>
          <title>All recipes | Recipe finder</title>
          <meta name="description" content={`Filter all recipes`} />
        </Helmet>

        <div className="container container--page">
          <h1>Find a recipe</h1>

          <ArticleFilters tags={this.props.tags} />

          {this.renderPageBody(filteredArticles)}
        </div>
      </>
    );
  }
}

TagPage.propTypes = {
  articles: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  return {
    articles: getAllArticles(state),
    tags: getAllTags(state),
    filteredTags: getFilteredTagSlugs(state),
  };
};

export default withRouter(connect(mapStateToProps, null)(TagPage));
