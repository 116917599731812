import React from "react";
import PropTypes from "prop-types";

import DocumentToHtml from "src/helpers/renderers/documentToHtml";
import Title from "src/components/atoms/title/Title";

import "./ArticleIngredients.scss";

function ArticleIngredients(props) {
  const { ingredients } = props;

  return (
    <div className="article-ingredients shadow--light">
      <Title tag="h2" className="article-ingredients__title">
        Ingredients
      </Title>
      <DocumentToHtml document={ingredients} />
    </div>
  );
}

ArticleIngredients.propTypes = {
  ingredients: PropTypes.object.isRequired,
};

export default ArticleIngredients;
