import React from "react";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";

const Image = ({ imageObj, imageClass, height = "100%" }) => (
  <LazyLoad height={height} once={true}>
    <img src={imageObj.url} alt={imageObj.alt} className={imageClass} />
  </LazyLoad>
);

Image.propTypes = {
  imageObj: PropTypes.object.isRequired,
  height: PropTypes.number,
  imageClass: PropTypes.string,
};

export default Image;
