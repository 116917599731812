export function articlesFetchError(bool) {
  return {
      type: 'ARTICLE_FETCH_ERROR',
      hasErrored: bool
  };
}
export function articlesLoading(bool) {
  return {
      type: 'ARTICLES_LOADING',
      isLoading: bool
  };
}
export function articlesFetched(articles) {
  return {
      type: 'ARTICLES_FETCHED',
      articles
  };
}