export function articlesFetchError(state = false, action) {
  switch (action.type) {
    case "ARTICLE_FETCH_ERROR":
      return action.hasErrored;
    default:
      return state;
  }
}
export function articlesLoading(state = true, action) {
  switch (action.type) {
    case "ARTICLES_LOADING":
      return action.isLoading;
    default:
      return state;
  }
}
export function articles(state = [], action) {
  switch (action.type) {
    case "ARTICLES_FETCHED":
      return action.articles;
    default:
      return state;
  }
}
