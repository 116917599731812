import React from "react";
import PropTypes from "prop-types";

import LocationIcon from "src/components/molecules/shop-card/location-pin.svg";
import UrlIcon from "src/components/molecules/shop-card/url-link.svg";
import ShopLink from "src/components/atoms/shop-link/ShopLink";

import "src/components/molecules/shop-card/ShopCard.scss";

const shopCard = ({ shop }) => (
  <li className="shop-card">
    <div
      className="shop-card__inner shadow"
      href={shop.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <h3 className="shop-card__title">{shop.name}</h3>
      <p className="shop-card__description">{shop.description}</p>

      {(shop.shopWebsiteUrl || shop.shopMapsUrl) && (
        <ul className="shop-card__links">
          {shop.shopWebsiteUrl && (
            <ShopLink url={shop.shopWebsiteUrl} icon={UrlIcon} type="website">
              Visit <span className="visually-hidden">{shop.name}'s</span>{" "}
              website
            </ShopLink>
          )}
          {shop.shopMapsUrl && (
            <ShopLink
              url={shop.shopMapsUrl}
              icon={LocationIcon}
              type="location"
            >
              View <span className="visually-hidden">{shop.name}</span> on map
            </ShopLink>
          )}
        </ul>
      )}
    </div>
  </li>
);
shopCard.propTypes = {
  shop: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    shopWebsiteUrl: PropTypes.string,
    shopMapsUrl: PropTypes.string,
  }).isRequired,
};

export default shopCard;
