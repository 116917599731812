const ArticleFiltersStyleConfig = {
  multiselectContainer: {
    // To change css for multiselect (Width,height,etc..)
    borderRadius: 0,
    background: "white",
    // display: "flex",
  },
  searchBox: {
    // To change search box element look
    fontSize: "16px",
    borderRadius: 0,
    padding: "10px 20px",
    flexGrow: 1,
  },
  inputField: {
    // To change input field position or margin
    padding: "10px 0",
    width: "auto",
    height: "auto",
  },
  chips: {
    // To change css chips(Selected options)
    background: "#667762",
    margin: "10px 10px 10px 0",
    fontSize: "14px",
    padding: "6px 10px 6px 12px",
  },
  optionContainer: {
    // To change css for option container
    borderRadius: 0,
    paddingLeft: 0,
    marginTop: "-1px",
  },
  option: {
    // To change css for dropdown options
    paddingLeft: "15px",
    color: "#002b3e",
    fontSize: "15px",
  },
  groupHeading: {
    // To change group heading style
    textTransform: "capitalize",
    padding: "15px 15px 10px",
    color: "#667762",
    fontSize: "18px",
    fontWeight: "bold",
  },
};

export default ArticleFiltersStyleConfig;
