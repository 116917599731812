import React, { useState, useEffect, useRef } from "react";
import Logo from "src/components/atoms/logo/Logo";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { tagsFiltered } from "src/redux/actions/tagActions";
import MenuIcon from "src/components/molecules/page-top/menu.svg";
import CloseIcon from "src/components/molecules/page-top/close.svg";

import "./PageTop.scss";

const PageTop = ({ setTagsFiltered }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const navWrapperRef = useRef();
  let location = useLocation();

  useEffect(() => {
    // make sure we can tab at desktop
    setIsMobile(window.innerWidth < 600 ? true : false);
  }, []);

  useEffect(() => {
    setMenuIsOpen(false);
  }, [location]);

  // handle closing mobile nav by clicking outside of it if it's open
  useEffect(() => {
    if (menuIsOpen) {
      document.addEventListener("mousedown", handlePageClick);
    } else {
      document.removeEventListener("mousedown", handlePageClick);
    }
  }, [menuIsOpen]);

  const handlePageClick = (e) => {
    if (!navWrapperRef.current.contains(e.target)) {
      setMenuIsOpen(false);
      return;
    }
  };

  return (
    <div className="container">
      <nav>
        <div className="page-top">
          <NavLink to="/" className="page-top__home-link">
            <Logo /> <span className="visually-hidden">home</span>
          </NavLink>

          <a //@todo make a button
            href="#"
            role="button"
            className="page-top__links-toggle"
            aria-controls="nav-menu"
            aria-expanded={menuIsOpen}
            onClick={(e) => {
              e.preventDefault();
              setMenuIsOpen(!menuIsOpen);
            }}
          >
            <img
              className="page-top__links-toggle-icon"
              src={MenuIcon}
              alt=""
              aria-hidden="true"
            />
            <span className="visually-hidden">Open navigation</span>
          </a>

          <div
            ref={navWrapperRef}
            className={`page-top__links-wrapper ${
              menuIsOpen ? "page-top__links-wrapper--open" : ""
            }`}
            id="nav-menu"
          >
            <div className="page-top__links">
              <NavLink
                className="page-top__links-link"
                to="/"
                exact
                activeClassName="page-top__links-link--active"
                tabIndex={!menuIsOpen && isMobile ? "-1" : "0"}
                onClick={() => setTagsFiltered([])}
              >
                All recipes
              </NavLink>
              <NavLink
                className="page-top__links-link"
                to="/seasonal-info"
                exact
                activeClassName="page-top__links-link--active"
                tabIndex={!menuIsOpen && isMobile ? "-1" : "0"}
              >
                Seasonal info
              </NavLink>
              <NavLink
                className="page-top__links-link"
                to="/shop-local"
                exact
                activeClassName="page-top__links-link--active"
                tabIndex={!menuIsOpen && isMobile ? "-1" : "0"}
              >
                Shop local
              </NavLink>

              <a
                href="#"
                role="button"
                className="page-top__links-wrapper-close"
                onClick={(e) => {
                  e.preventDefault();
                  setMenuIsOpen(false);
                }}
                tabIndex={!menuIsOpen && isMobile ? "-1" : "0"}
              >
                <img
                  className="page-top__links-wrapper-close-icon"
                  src={CloseIcon}
                  alt=""
                  aria-hidden="true"
                />
                <span className="visually-hidden">Close navigation</span>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

PageTop.propTypes = {
  setTagsFiltered: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  setTagsFiltered: tagsFiltered,
};

export default withRouter(connect(null, mapDispatchToProps)(PageTop));
