import React from "react";

import "src/components/atoms/logo/Logo.scss";

const Logo = ({ greyscale = false }) => (
  <span className={`logo ${greyscale ? "logo--grey" : ""}`}>
    <span className="logo-text">
      R<span className="visually-hidden">ecipe finder</span>
    </span>
  </span>
);
export default Logo;
