import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

function ArticleMeta(props) {
  const { name, teaser, heroImage } = props.article;

  return (
    <Helmet>
      <title>
        {name} | Recipe finder{name}
      </title>
      <meta name="description" content={teaser} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={`Recipe finder | ${name}`} />
      <meta property="og:description" content={teaser} />
      <meta property="og:image" content={heroImage.url} />
      <meta property="og:url" content={window.location.href} />
      <meta name="twitter:title" content={`Recipe finder | ${name}`} />
      <meta name="twitter:description" content={teaser} />
      <meta name="twitter:image" content={heroImage.url} />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

ArticleMeta.propTypes = {
  article: PropTypes.object.isRequired,
};

export default ArticleMeta;
