import React from "react";
import PropTypes from "prop-types";

function Title(props) {
  const CustomTag = props.tag ? props.tag : "h1";

  return <CustomTag className={props.className}>{props.children}</CustomTag>;
}

Title.propTypes = {
  className: PropTypes.string.isRequired,
  tag: PropTypes.oneOf(["h1", "h2", "h3", "h4"]),
};

export default Title;
