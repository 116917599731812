import ContentfulClient from "src/clients/contentful/ContentfulClient";
import {
  seasonalIngredientsFetched,
  seasonalIngredientsFetchError,
  seasonalIngredientsLoading,
} from "src/redux/actions/seasonalIngredientsActions";

const getSeasonalIngredients = () => (dispatch) => {
  ContentfulClient.getEntries({
    content_type: "seasonalRecipePage",
  })
    .then((entries) => {
      const seasonalIngredientsFields = entries.items.map(
        (ingredient) => ingredient.fields
      )[0];

      let seasonalIngredients = {
        summer: {
          june: seasonalIngredientsFields.june,
          july: seasonalIngredientsFields.july,
          august: seasonalIngredientsFields.august,
        },
        autumn: {
          september: seasonalIngredientsFields.september,
          october: seasonalIngredientsFields.october,
          november: seasonalIngredientsFields.november,
        },
        winter: {
          december: seasonalIngredientsFields.december,
          january: seasonalIngredientsFields.january,
          february: seasonalIngredientsFields.february,
        },
        spring: {
          march: seasonalIngredientsFields.march,
          april: seasonalIngredientsFields.april,
          may: seasonalIngredientsFields.may,
        },
      };

      dispatch(seasonalIngredientsFetched(seasonalIngredients));

      return dispatch(seasonalIngredientsLoading(false));
    })
    .catch(() => dispatch(seasonalIngredientsFetchError(true)));
};

export { getSeasonalIngredients };
