import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";

import Analytics from "src/Analytics";
import configureStore from "src/redux/store/configureStore";
import App from "src/App";
import ArticleSingle from "src/components/organisms/article-single/ArticleSingle";
import TagPage from "src/pages/tag-page/TagPage";
import NotFound from "src/pages/not-found/NotFound";
import ScrollToTop from "src/components/atoms/scroll-to-top/ScrollToTop";
import SeasonalInfo from "src/pages/seasonal-info/SeasonalInfo";
import ShopLocal from "src/pages/shop-local/ShopLocal";

const store = configureStore();

const router = (
  <Provider store={store}>
    <Router>
      <ScrollToTop />
      <App>
        <Switch>
          <Route exact path="/">
            <TagPage />
          </Route>

          <Route exact path="/recipe/:slug" component={ArticleSingle} />
          <Route exact path="/seasonal-info" component={SeasonalInfo} />
          <Route exact path="/shop-local" component={ShopLocal} />

          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
        <Analytics />
      </App>
    </Router>
  </Provider>
);

render(router, document.getElementById("root"));
