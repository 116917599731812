export function shopsFetchError(state = false, action) {
  switch (action.type) {
    case "SHOPS_FETCH_ERROR":
      return action.hasErrored;
    default:
      return state;
  }
}
export function shopsLoading(state = true, action) {
  switch (action.type) {
    case "SHOPS_LOADING":
      return action.isLoading;
    default:
      return state;
  }
}
export function shops(state = [], { type, shops }) {
  switch (type) {
    case "SHOPS_FETCHED":
      return shops;
    default:
      return state;
  }
}
export function shopsFiltered(state = [], { type, shops }) {
  switch (type) {
    case "SHOPS_FILTERED":
      return shops;
    default:
      return state;
  }
}
