import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => (
  <div className="container">
    <h2>Page not found</h2>
    <p>
      Go back to the <Link to="/">recipes</Link> page
    </p>
  </div>
);

export default NotFound;
