import ContentfulClient from "src/clients/contentful/ContentfulClient";
import {
  shopsFetched,
  shopsFetchError,
  shopsLoading,
} from "src/redux/actions/shopActions";

const getShops = () => (dispatch) => {
  ContentfulClient.getEntries({
    content_type: "shop",
  })
    .then((entries) => {
      const formattedShops = entries.items.map((shop) => shop.fields);

      // sort alphabetically
      formattedShops.sort((a, b) => (a.name > b.name ? 1 : -1));

      dispatch(shopsFetched(formattedShops));

      return dispatch(shopsLoading(false));
    })
    .catch(() => dispatch(shopsFetchError(true)));
};

export { getShops };
