export function tagsFetchError(state = false, action) {
  switch (action.type) {
      case 'TAGS_FETCH_ERROR':
          return action.hasErrored;
      default:
          return state;
  }
}
export function tagsLoading(state = true, action) {
  switch (action.type) {
      case 'TAGS_LOADING':
          return action.isLoading;
      default:
          return state;
  }
}
export function tags(state = [], { type, tags }) {
  switch (type) {
      case 'TAGS_FETCHED':
          return tags;
      default:
          return state;
  }
}
export function tagsFiltered(state = [], { type, tags }) {
  switch (type) {
      case 'TAGS_FILTERED':
          return tags;
      default:
          return state;
  }
}
