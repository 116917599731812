import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Image from "src/components/atoms/image/Image";
import ImagePlaceholder from "src/components/atoms/imagePlaceholder/ImagePlaceholder";
import ArticleIngredients from "src/components/molecules/article-ingredients/ArticleIngredients";
import { tagsFiltered } from "src/redux/actions/tagActions";

import "src/components/molecules/article-info/ArticleInfo.scss";
const ArticleInfo = ({
  heroImage,
  ingredients,
  serves,
  cooking_time,
  tags,
  tagsFiltered,
}) => {
  const cuisineTag = tags && tags.find((tag) => tag.type === "cuisine");

  return (
    <div className="grid__wrapper">
      <div className="grid">
        <div className="grid__half">
          {isEmpty(heroImage) ? (
            <ImagePlaceholder classes="image-placeholder--article" />
          ) : (
            <Image imageObj={heroImage} imageClass="article-info__image" />
          )}
        </div>
        <div className="grid__half grid__half--column">
          <div className="article-info shadow--light">
            {cuisineTag && (
              <>
                <h3 className="visually-hidden">Cuisine</h3>
                <Link
                  className="article-info__block article-info__block--cuisine"
                  onClick={() => tagsFiltered([cuisineTag])}
                  to="/"
                >
                  {cuisineTag.title}
                </Link>
              </>
            )}
            <div className="article-info__block article-info__block--split">
              <div>
                <h3 className="visually-hidden">Servings</h3>
                <span className="article-info__block-number">{serves}</span>
                <span className="article-info__block-label">
                  {serves > 1 ? "servings" : "servings"}
                </span>
              </div>
              <div>
                <h3 className="visually-hidden">Cooking time</h3>
                <span className="article-info__block-number">
                  {cooking_time}
                </span>
                <span className="article-info__block-label">mins</span>
              </div>
            </div>
          </div>

          <ArticleIngredients ingredients={ingredients} />
        </div>
      </div>
    </div>
  );
};

ArticleInfo.propTypes = {
  heroImage: PropTypes.object.isRequired,
  ingredients: PropTypes.object.isRequired,
  serves: PropTypes.number.isRequired,
  cooking_time: PropTypes.number.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    tagsFiltered: (tags) => dispatch(tagsFiltered(tags)),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(ArticleInfo));
