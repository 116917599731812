export function shopsFetchError(bool) {
  return {
    type: "SHOPS_FETCH_ERROR",
    hasErrored: bool,
  };
}
export function shopsLoading(bool) {
  return {
    type: "SHOPS_LOADING",
    isLoading: bool,
  };
}
export function shopsFetched(shops) {
  return {
    type: "SHOPS_FETCHED",
    shops,
  };
}
export function shopsFiltered(shops) {
  return {
    type: "SHOPS_FILTERED",
    shops,
  };
}
