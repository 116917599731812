const CustomStyles = {
  clearIndicator: (provided, state) => ({
    ...provided,
  }),
  container: (provided, state) => ({
    ...provided,
    marginBottom: 20,
  }),
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? "#667762" : "#667762",
    boxShadow: state.isFocused ? "0 0 0 1px #667762" : "",
    "&:hover, &:focus": {
      borderColor: "#667762",
      boxShadow: "0 0 0 1px #667762",
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
  }),
  group: (provided, state) => ({
    ...provided,
  }),
  groupHeading: (provided, state) => ({
    ...provided,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
  }),
  input: (provided, state) => ({
    ...provided,
  }),
  loadingIndicator: (provided, state) => ({
    ...provided,
  }),
  loadingMessage: (provided, state) => ({
    ...provided,
  }),
  menu: (provided, state) => ({
    ...provided,
  }),
  menuList: (provided, state) => ({
    ...provided,
    marginTop: -12,
  }),
  menuPortal: (provided, state) => ({
    ...provided,
  }),
  multiValue: (provided, state) => ({
    ...provided,
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
  }),
  option: (provided, state) => ({
    ...provided,
    padding: 20,
    color: state.isSelected ? "white" : "#595959",
    background: state.isSelected
      ? "#667762"
      : state.isFocused
      ? "f2f2f2"
      : "white",
    "&:hover": {
      // background: "orange",
      background: "f2f2f2",
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
  }),
  singleValue: (provided, state) => ({
    ...provided,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: 20,
  }),
};

export default CustomStyles;
