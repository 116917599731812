import ContentfulClient from "src/clients/contentful/ContentfulClient";
import {
  tagsFetched,
  tagsFetchError,
  tagsLoading,
} from "src/redux/actions/tagActions";

const getTags = () => (dispatch) => {
  ContentfulClient.getEntries({
    content_type: "tag",
  })
    .then((entries) => {
      const formattedTags = entries.items.map((tag) => ({
        id: tag.sys.id,
        title: tag.fields.title,
        slug: tag.fields.slug,
        type: tag.fields.type,
      }));

      // sort alphabetically
      formattedTags.sort((a, b) => (a.title > b.title ? 1 : -1));

      dispatch(tagsFetched(formattedTags));

      return dispatch(tagsLoading(false));
    })
    .catch(() => dispatch(tagsFetchError(true)));
};

export { getTags };
