import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import NotFound from "src/pages/not-found/NotFound";
import PageHeader from "src/components/molecules/page-header/PageHeader";
import ArticleSteps from "src/components/molecules/article-steps/ArticleSteps";
import ArticleMeta from "src/components/atoms/articleMeta/ArticleMeta";
import ArticleInfo from "src/components/molecules/article-info/ArticleInfo";

import { getAllArticles } from "src/redux/selectors/articleSelector";

import "src/components/organisms/article-single/ArticleSingle.scss";

class ArticleSingle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      articleLoaded: false,
      article: {},
    };
  }

  componentDidMount() {
    if (this.props.articles.length) {
      this.getArticle();
    }
  }

  componentDidUpdate() {
    if (!this.state.articleLoaded) {
      this.getArticle();
    }
  }

  getArticle() {
    const {
      articles,
      match: { params },
    } = this.props;

    const article = articles.find((article) => article.slug === params.slug);

    this.setState({
      article,
      articleLoaded: true,
    });
  }

  render() {
    const article = this.state.article;
    if (!article) {
      return <NotFound message={"No recipes!"} />;
    }

    const {
      name,
      teaser,
      tags,
      ingredients,
      steps,
      heroImage,
      cooking_time,
      serves,
    } = article;

    if (!this.state.articleLoaded) {
      return <div>Loading 2</div>;
    }

    return (
      <>
        <ArticleMeta article={article} />

        <PageHeader title={name} introText={teaser} />

        <ArticleInfo
          heroImage={heroImage}
          ingredients={ingredients}
          serves={serves}
          cooking_time={cooking_time}
          tags={tags}
        />

        <div className="bg-flat--large shadow--light">
          <div className="container">
            <ArticleSteps steps={steps} />
          </div>
        </div>
      </>
    );
  }
}

ArticleSingle.propTypes = {
  articles: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  return {
    articles: getAllArticles(state),
  };
};

export default withRouter(connect(mapStateToProps, null)(ArticleSingle));
