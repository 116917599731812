const getTagsLoadingStatus = (state) => state.tagsLoading;

const getAllTags = (state) => {
  const tags = state.tags;

  const seasonTags = tags.filter((tag) => tag.type === "season");
  const cuisineTags = tags.filter((tag) => tag.type === "cuisine");
  const courseTags = tags.filter((tag) => tag.type === "course");
  const typeTags = tags.filter((tag) => tag.type === "type");

  const orderedTypes = [
    ...typeTags,
    ...cuisineTags,
    ...courseTags,
    ...seasonTags,
    // ...otherTags
  ];
  return orderedTypes;
};

const getSeasonTags = (state) => {
  const tags = state.tags;
  const seasonTags = tags.filter((tag) => tag.type === "season");
  return seasonTags;
};

const getFilteredTags = (state) => state.tagsFiltered;

const getFilteredTagSlugs = (state) => {
  return state.tagsFiltered.map((tag) => tag.slug);
};

export {
  getTagsLoadingStatus,
  getAllTags,
  getSeasonTags,
  getFilteredTags,
  getFilteredTagSlugs,
};
