import React from "react";

import Logo from "src/components/atoms/logo/Logo";

import "src/components/atoms/imagePlaceholder/ImagePlaceholder.scss";

const ImagePlaceholder = ({ minHeight, classes }) => (
  <div
    className={`image-placeholder ${classes ? classes : ""}`}
    style={{ minHeight: `${minHeight}px` }}
  >
    <Logo greyscale />
  </div>
);

export default ImagePlaceholder;
