import React from "react";
import PropTypes from "prop-types";

import "src/components/atoms/shop-link/ShopLink.scss";

const ShopLink = ({ icon, url, type, children }) => {
  return (
    <li className="shop-link__item">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img
          className={`shop-link__icon shop-link__icon--${type}`}
          src={icon}
          alt=""
          aria-hidden="true"
        />
        <span>{children}</span>
      </a>
    </li>
  );
};

ShopLink.propTypes = {
  type: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default ShopLink;
