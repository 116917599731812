import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

import { Link } from "react-router-dom";

import Image from "src/components/atoms/image/Image";
import ImagePlaceholder from "src/components/atoms/imagePlaceholder/ImagePlaceholder";

import "src/components/molecules/article-card/ArticleCard.scss";

const ArticleCard = (props) => {
  const {
    article,
    article: { cooking_time, thumbnailImage, name, tags, slug, serves },
  } = props;

  return (
    <li className="article-card">
      <Link className="article-card__link shadow" to={`/recipe/${slug}`}>
        <div className="article-card__top">
          {isEmpty(thumbnailImage) ? (
            <ImagePlaceholder classes="image-placeholder--thumbnail" />
          ) : (
            <Image imageObj={thumbnailImage} imageClass="article-card__image" />
          )}
        </div>
        <div className="article-card__bottom">
          <span className="article-card__time">{cooking_time} mins</span>
          <h3 className="article-card__title">{article.name}</h3>
        </div>
      </Link>
    </li>
  );
};

ArticleCard.propTypes = {
  article: PropTypes.object.isRequired,
};

export default ArticleCard;
