import React from 'react';
import Title from 'src/components/atoms/title/Title';
import PropTypes from 'prop-types';

function ArticleSection(props) {
  return (
    <>
      <Title tag="h2" className="article-single__title">{props.title}</Title>

      {props.children}
    </>
  )
}

ArticleSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default ArticleSection
