import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { forceCheck } from "react-lazyload";
import { Multiselect } from "multiselect-react-dropdown";

import { tagsFiltered } from "src/redux/actions/tagActions";
import { getFilteredTags } from "src/redux/selectors/tagSelector";
import ArticleFiltersStyleConfig from "src/components/molecules/article-filters/ArticleFiltersStyleConfig";
import "src/components/molecules/article-filters/ArticleFilters.scss";

const fieldId = "tags-filter";

const ArticleFilters = ({ tags, initialFilteredTags, tagsFiltered }) => {
  const multiselectRef = useRef(null);

  const [allTags, setTags] = useState(tags);
  const [filteredTags, setFilteredTags] = useState(initialFilteredTags);

  useEffect(() => {
    setTags(tags);
  }, [tags]);

  useEffect(() => {
    setTimeout(function () {
      forceCheck();
    }, 500);
  }, [tags, filteredTags]);

  const onChange = (selectedList) => {
    tagsFiltered(selectedList);

    setFilteredTags(selectedList);

    document.getElementById(fieldId).blur();
  };

  return (
    <div className="article-filters__container">
      <label className="article-filters__label" htmlFor={`${fieldId}_input`}>
        Filter by tags
      </label>

      <Multiselect
        ref={multiselectRef}
        id={fieldId}
        closeOnSelect={true}
        options={allTags}
        selectedValues={filteredTags}
        showCheckbox={true}
        onSelect={onChange}
        onRemove={onChange}
        displayValue="title"
        groupBy="type"
        placeholder="Enter filter"
        closeIcon="cancel"
        style={ArticleFiltersStyleConfig}
      />

      {filteredTags.length ? (
        <button
          className="link-button article-filters__link"
          onClick={() => onChange([])}
        >
          Clear filter{filteredTags.length === 1 ? "" : "s"}
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

ArticleFilters.propTypes = {
  tags: PropTypes.array.isRequired,
  initialFilteredTags: PropTypes.array,
  tagsFiltered: PropTypes.func,
};

const mapStateToProps = (state) => ({
  initialFilteredTags: getFilteredTags(state),
});

const mapDispatchToProps = {
  tagsFiltered: tagsFiltered,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArticleFilters)
);
