import ContentfulClient from "src/clients/contentful/ContentfulClient";
import {
  articlesFetched,
  articlesFetchError,
  articlesLoading,
} from "src/redux/actions/articleAction";

const getArticles = () => {
  return (dispatch) => {
    ContentfulClient.getEntries({
      content_type: "article",
      order: "-sys.createdAt",
      limit: 200,
    })
      .then((entries) => {
        const formattedArticles = entries.items.map((entry) => {
          const {
            cooking_time,
            image,
            ingredients,
            name,
            steps,
            tags,
            teaser,
            slug,
            serves,
          } = entry.fields;

          let formattedTags;
          let heroImage = {};
          let thumbnailImage = {};

          if (tags) {
            formattedTags = tags.map((tag) => ({
              id: tag.sys.id,
              title: tag.fields.title,
              slug: tag.fields.slug,
              type: tag.fields.type,
            }));
          }

          if (image) {
            const { public_id, version, format } = image[0];

            heroImage = {
              url: `https://res.cloudinary.com/dfvhyk2ut/image/upload/f_auto,q_auto,w_700/v${version}/${public_id}.${format}`,
              alt: name,
            };
            thumbnailImage = {
              url: `https://res.cloudinary.com/dfvhyk2ut/image/upload/f_auto,q_auto,w_360/v${version}/${public_id}.${format}`,
              alt: name,
            };
          }

          return {
            id: entry.sys.id,
            cooking_time,
            ingredients,
            name,
            steps,
            tags: formattedTags,
            teaser,
            heroImage,
            thumbnailImage,
            slug,
            serves: serves ? serves : 2,
          };
        });

        // sort by aphabetical order
        // formattedArticles.sort((a, b) => (a.name > b.name ? 1 : -1));

        dispatch(articlesFetched(formattedArticles));

        return dispatch(articlesLoading(false));
      })
      .catch(() => dispatch(articlesFetchError(true)));
  };
};
export { getArticles };
