export function seasonalIngredientsFetchError(state = false, action) {
  switch (action.type) {
    case "SEASONAL_INGREDIENTS_FETCH_ERROR":
      return action.hasErrored;
    default:
      return state;
  }
}
export function seasonalIngredientsLoading(state = true, action) {
  switch (action.type) {
    case "SEASONAL_INGREDIENTS_LOADING":
      return action.isLoading;
    default:
      return state;
  }
}
export function seasonalIngredients(state = {}, { type, seasonalIngredients }) {
  switch (type) {
    case "SEASONAL_INGREDIENTS_FETCHED":
      return seasonalIngredients;
    default:
      return state;
  }
}
