import React from "react";
import PropTypes from "prop-types";

import Title from "src/components/atoms/title/Title";
import "./PageHeader.scss";

function PageHeader(props) {
  const { title, introText } = props;

  return (
    <header className="page-header">
      <div className="container">
        <div className="page-header__top">
          <Title className="page-header__title">{title}</Title>

          {introText && <p className="page-header__intro-text">{introText}</p>}
        </div>
      </div>
    </header>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  teaser: PropTypes.string,
};

export default PageHeader;
