import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import PageHeader from "src/components/molecules/page-header/PageHeader";
import { getSeasonalIngredients } from "src/redux/thunks/seasonal-ingredients/getSeasonalIngredients";
import {
  getAllSeasonalIngredients,
  getSeasonalIngredientsLoadingStatus,
} from "src/redux/selectors/seasonalIngredientsSelector";
import Loader from "src/components/atoms/loader/Loader";
import SeasonList from "src/components/organisms/season-list/SeasonList";

const SeasonalInfo = ({
  allSeasonalIngredients,
  seasonalIngredientsLoading,
  loadSeasonalIngredients,
}) => {
  useEffect(() => {
    if (isEmpty(allSeasonalIngredients)) {
      loadSeasonalIngredients();
    }
  }, [allSeasonalIngredients]);

  return (
    <>
      <Helmet>
        <title>Seasonal info | Recipe Finder</title>
        <meta
          name="description"
          content={`Learn about seasonal ingredients and recipes to help reduce the environmental cost of your eating habits.`}
        />
      </Helmet>
      {seasonalIngredientsLoading ? (
        <div className="container container--page">
          <Loader />
        </div>
      ) : (
        <>
          <PageHeader
            title="Seasonal info"
            introText="Eating seasonal food will reduce the environmental costs associated with the ingredients you eat. By eating seasonal, you’ll be eating ingredients grown more locally, with less energy spent on transport, refrigerating and storing the food. An added bonus is you’ll most likely be supporting local businesses and your community."
          />

          <div className="container container--page">
            <ol className="season-list">
              {Object.keys(allSeasonalIngredients).map((season, i) => (
                <SeasonList
                  key={i}
                  season={season}
                  months={allSeasonalIngredients[season]}
                />
              ))}
            </ol>
          </div>
        </>
      )}
    </>
  );
};

SeasonalInfo.propTypes = {
  loadSeasonalIngredients: PropTypes.func.isRequired,
  seasonalIngredientsLoading: PropTypes.bool.isRequired,
  allSeasonalIngredients: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  seasonalIngredientsLoading: getSeasonalIngredientsLoadingStatus(state),
  allSeasonalIngredients: getAllSeasonalIngredients(state),
});

const mapDispatchToProps = {
  loadSeasonalIngredients: getSeasonalIngredients,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SeasonalInfo)
);
