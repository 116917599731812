import React from "react";

import ArticleCard from "src/components/molecules/article-card/ArticleCard";

// CSS
import "./ArticleList.scss";

const ArticleList = ({ articles }) => {
  return (
    <ul className="article-list">
      {articles.map((article) => (
        <ArticleCard key={article.id} article={article} />
      ))}
    </ul>
  );
};

// @TODO - propTypes

export default ArticleList;
