import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import MonthListItem from "src/components/molecules/month-list-item/MonthListItem";
import { tagsFiltered } from "src/redux/actions/tagActions";
import { getSeasonTags } from "src/redux/selectors/tagSelector";

import "src/components/organisms/season-list/SeasonList.scss";

const SeasonList = ({ months, season, setTagsFiltered, seasonTags }) => {
  const setTagFilter = (season) => {
    const selectedSeasonTag =
      seasonTags && seasonTags.find((tag) => tag.slug === season);
    setTagsFiltered([selectedSeasonTag]);
  };

  return (
    <>
      <li className="season-list__item">
        <h2 className="season-list__title text-capitalise">{season}</h2>
        <p>
          Learn what's in season for {season}, or browse our{" "}
          <Link onClick={() => setTagFilter(season)} to="/">
            {season} recipes
          </Link>
          .
        </p>

        <ol className="month-list">
          {Object.keys(months).map((month, i) => (
            <MonthListItem key={i} month={month} ingredients={months[month]} />
          ))}
        </ol>
      </li>
    </>
  );
};

SeasonList.propTypes = {
  months: PropTypes.object.isRequired,
  season: PropTypes.string.isRequired,
  setTagsFiltered: PropTypes.func.isRequired,
  seasonTags: PropTypes.array.isRequired,
};

const mapDispatchToProps = {
  setTagsFiltered: tagsFiltered,
};

const mapStateToProps = (state) => {
  return {
    seasonTags: getSeasonTags(state),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SeasonList)
);
