import React from 'react';
import PropTypes from 'prop-types';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

// import Ingredient from 'src/components/molecules/ingredient/Ingredient';

function DocumentToHtml(props) {
  const { document } = props;

  // See: https://www.npmjs.com/package/@contentful/rich-text-react-renderer
  const options = {
    renderNode: {
      [BLOCKS.LIST_ITEM]: (node, children) => <li key={`${Math.random()}`}>{children}</li>,
      [BLOCKS.PARAGRAPH]: (node, children) => <p key={`${Math.random()}`}>{children}</p>,
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        switch (node.data.target.sys.contentType.sys.id) {
          // case 'ingredient': {
          //   const { ingredient_name, ingredient_image } = node.data.target.fields;
          //   return <Ingredient name={ingredient_name} image={ingredient_image} />
          // }
          default:
             return <div>Fallback Element</div>
        }
      }
    },
    renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br key={`${Math.random()}`} />, text]),
    renderMark: {
      [MARKS.BOLD]: text => {
      return <strong key={`${Math.random()}`}>{text}</strong>;
      }
    },
  }

  return documentToReactComponents(document, options);
}

DocumentToHtml.propTypes = {
  document: PropTypes.object.isRequired
};

export default DocumentToHtml
