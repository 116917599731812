import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

// CSS
// import "./Select.scss";
import SelectStylesConfig from "src/components/atoms/select/SelectStylesConfig";

const SelectField = ({
  label,
  ariaId,
  nameId,
  defaultValue,
  isSearchable = false,
  isMulti = false,
  onChange,
  options,
}) => {
  return (
    <>
      <label id={ariaId} htmlFor={nameId}>
        {label}
      </label>

      <Select
        aria-labelledby={ariaId}
        inputId={nameId}
        options={options}
        defaultValue={defaultValue}
        isSearchable={isSearchable}
        className="react-select__container"
        classNamePrefix="react-select"
        onChange={(location) => {
          onChange(location.value);
        }}
        styles={SelectStylesConfig}
        isMulti={isMulti}
      />
    </>
  );
};

SelectField.propTypes = {
  label: PropTypes.string.isRequired,
  ariaId: PropTypes.string.isRequired,
  nameId: PropTypes.string.isRequired,
  defaultValue: PropTypes.object.isRequired,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default SelectField;
