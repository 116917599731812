import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import Select from "src/components/atoms/select/Select";

import ShopCard from "src/components/molecules/shop-card/ShopCard";

// CSS
import "./ShopsList.scss";

const defaultOption = "All";

const ShopsList = ({ allShops }) => {
  const cityOptions = [
    { label: "All", value: "All" },
    { label: "Birmingham", value: "Birmingham" },
    { label: "London", value: "London" },
    { label: "Sheffield", value: "Sheffield" },
    { label: "UK Wide/Online", value: "UK wide" },
  ];

  const [selectedCity, setSelectedCity] = useState(defaultOption);
  const [filteredShops, setFilteredShops] = useState(allShops);

  useEffect(() => {
    if (selectedCity) {
      let filteredShops;

      if (selectedCity === defaultOption) {
        filteredShops = allShops;
      } else {
        filteredShops = allShops.filter(
          (shop) => shop.location === selectedCity
        );
      }
      setFilteredShops(filteredShops);
    } else {
      setFilteredShops(allShops);
    }
  }, [selectedCity]);

  return (
    <>
      <Select
        label="Select a location"
        ariaId="city-select-id"
        nameId="citySelect"
        defaultValue={cityOptions[0]}
        onChange={setSelectedCity}
        options={cityOptions}
      />

      {isEmpty(filteredShops) ? (
        <p>No shops for selected location.</p>
      ) : (
        <ul className="shops-list">
          {filteredShops.map((shop) => (
            <ShopCard key={`${shop.name}-${shop.location}`} shop={shop} />
          ))}
        </ul>
      )}
    </>
  );
};

ShopsList.propTypes = {
  allShops: PropTypes.array,
};

export default ShopsList;
